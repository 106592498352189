import { Box, Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { SimSteps } from '../models';
import { BaseInfoPage } from './BaseInfoPage';
import BasePage, { StepWizardPageProps } from './BasePage';

export const SurveyWelcomePage: React.FunctionComponent<StepWizardPageProps> = (props) => {

    return (
        <BasePage
            stepWizardProps={props as unknown as StepWizardChildProps}
            useDarkTheme={false}
            userUpdates={{}}>
        <Box>
            <Grid container rowSpacing={2} >
                <Grid container justifyContent={'center'} xs={12}>
                        <img src="/images/maine_mbuf_logo.png" alt="ME RUC Logo" style={{ width: '150px', marginRight: '300px', paddingBottom: '50px'}} />
                        <Typography variant='h5' sx={{alignSelf:'center'}}>
                            Simulation Survey
                        </Typography>
                </Grid>
                <Grid container justifyContent={'center'} xs={12}>
                    <Typography variant='h2'>
                        Welcome to the survey! Please hit the Next button below to proceed.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid xs={12}>
                    <Button sx={{float: 'right'}} variant="contained" onClick={props.nextPage}>Next</Button>
                </Grid>
            </Grid>
        </Box>
        </BasePage>

    );
}

SurveyWelcomePage.defaultProps = {
    stepName: SimSteps.Survey.name
};
