import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ISurvey } from "../models";
import { SurveyContext } from "../contexts/SurveyContext";

export {};

export interface TextAreaQuestionProps {
	explanation?: string;
	question: string;
	myKey: string;
	index: number;
	updateSurvey: (newSurvey: Partial<ISurvey>) => Promise<boolean>;
}

export function TextAreaQuestion({ explanation, question, myKey, index, updateSurvey }: TextAreaQuestionProps) {
	const { survey } = useContext(SurveyContext);
	const [value, setValue] = useState<string | undefined>((survey[myKey as keyof ISurvey] as string) ?? undefined);
	return (
		<>
			<Grid container justifyContent={"center"} xs={12}>
				<textarea
					placeholder="Type your answer here..."
					style={{ width: "600px", height: "100px" }}
					value={survey[myKey as keyof typeof survey]}
					onChange={(e) => updateSurvey({ [myKey]: e.target.value })}
				></textarea>
			</Grid>
		</>
	);
}
