import React, { useContext } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { ISurvey } from "../models";
import { SurveyContext } from "../contexts/SurveyContext";
import { surveyQuestions } from "../store";
import { RadioQuestion } from "./RadioQuestion";
import { SliderQuestion } from "./SliderQuestion";
import { CheckboxQuestion } from "./CheckboxQuestion";
import { VisualRadioQuestion } from "./VisualRadioQuestion";
import { TextAreaQuestion } from "./TextAreaQuestion";
import "./Question.css";

export interface QuestionProps {
	explanation?: string;
	question?: string;
	myKey: any;
	index: number;
	type: string;
	updateSurvey: (newSurvey: Partial<ISurvey>) => Promise<boolean>;
}

export const Question: React.FunctionComponent<QuestionProps> = ({ myKey, updateSurvey }: QuestionProps) => {
	const { survey } = useContext(SurveyContext);
	const questionsInGroup = Object.keys(surveyQuestions).filter((key) => {
		if (key.startsWith(myKey)) {
			const nextChar = key[myKey.length];
			if (isNaN(Number(nextChar))) {
				return key;
			}
		}
	});
	const getQuestionContent = (questionId: string, place?: number) => {
		const myQuestion = surveyQuestions[questionId];
		const parent = "parent" in myQuestion ? myQuestion.parent : undefined;
		let questionContent;
		const myType = myQuestion.type;
		const top =
			"question" in myQuestion ? (
				<div
					className="surveyQuestionTop"
					style={{ display: "flex", gap: "2em", alignItems: "center", marginBottom: "2em", width: "100%" }}
				>
					<div>
						{myQuestion.explanation && (
							<Typography className="explanation" variant="h2" marginBottom={2}>
								{myQuestion.explanation}
							</Typography>
						)}
						<Typography variant="h2">{myQuestion.question}</Typography>
					</div>
				</div>
			) : (
				<></>
			);
		try {
			const myIndex = Number(questionId.replace(/\D/g, ""));
			switch (myType) {
				case "radio":
					if ("question" in myQuestion) {
						questionContent = (
							<RadioQuestion
								question={myQuestion.question}
								myKey={questionId}
								index={myIndex}
								options={Object.keys(myQuestion.choices!)}
								updateSurvey={updateSurvey}
							></RadioQuestion>
						);
					}
					break;
				case "checkBox":
					if ("question" in myQuestion) {
						questionContent = (
							<CheckboxQuestion
								question={myQuestion.question}
								myKey={questionId}
								index={myIndex}
								options={Object.keys(myQuestion.choices!)}
								updateSurvey={updateSurvey}
							></CheckboxQuestion>
						);
					}
					break;
				case "slider":
					if ("question" in myQuestion) {
						questionContent = (
							<SliderQuestion
								myKey={questionId}
								units={myQuestion.units!}
								range={[myQuestion.buckets![0], myQuestion.buckets![1]]}
								updateSurvey={updateSurvey}
							></SliderQuestion>
						);
					}
					break;
				case "multipleChoice":
				case "multipleChoiceTextArea":
					if ("question" in myQuestion) {
						questionContent = (
							<VisualRadioQuestion
								myKey={questionId}
								options={Object.keys(myQuestion.choices!)}
								updateSurvey={updateSurvey}
							></VisualRadioQuestion>
						);
					}
					break;
				case "textArea":
					if ("question" in myQuestion) {
						questionContent = (
							<TextAreaQuestion
								explanation={myQuestion.explanation ?? ""}
								question={myQuestion.question}
								myKey={questionId}
								index={myIndex}
								updateSurvey={updateSurvey}
							></TextAreaQuestion>
						);
					} else {
						if (
							(parent && survey[parent as keyof typeof survey] === myQuestion.appearOn) ||
							survey[parent as keyof typeof survey].includes(myQuestion.appearOn!)
						)
							questionContent = (
								<textarea
									placeholder={myQuestion.placeholderText}
									style={{ width: "600px", height: "100px", margin: "auto" }}
									value={survey[questionId as keyof typeof survey] ?? ""}
									onChange={(e) => updateSurvey({ [questionId]: e.target.value })}
								></textarea>
							);
					}
					break;
				default:
					questionContent = <></>;
					break;
			}
		} catch (e) {
			console.log(e, myQuestion);
		}
		return (
			<div className="question-content" style={{ display: "flex", flexDirection: "column" }}>
				{top}
				{questionContent}
			</div>
		);
	};
	return (
		<>
			<div className="question-container">
				<h2>{myKey.replace(/\D/g, "")}</h2>
				<div className="question-content-container">
					{questionsInGroup.map((questionId, index) => getQuestionContent(questionId, index))}
				</div>
			</div>
			<div className="question-divider" />
		</>
	);
};
