import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { ISurvey, SimSteps, SurveyStatus } from "../../models";
import BasePage, { StepWizardPageProps } from "../BasePage";
import * as Server from "../../server";
import { UserContext } from "../../contexts";
import { surveyQuestions } from "../../store";
import { SurveyContext } from "../../contexts/SurveyContext";
import { Question } from "../../components/Question";
import { includes } from "lodash";
import "./SurveyPage.css";

export const SurveyPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user, updateUser } = React.useContext(UserContext);
	const [showSavePopup, setShowSavePopup] = React.useState(false);
	const stepWizard = props as unknown as StepWizardChildProps;
	const [survey, setSurvey] = React.useState<ISurvey>({
		id: user?.id ?? "",
		q1: "",
		q2: "0",
		q3: "",
		q4: "0",
		q5: [],
		q6: "",
		q6b: "",
		q7: "",
		q8: "0",
		q9: "",
		q9b: "",
		q10: "",
		q11: "",
		q12: [],
		q12b: "",
		q13: "",
		q13b: "",
		q14: "",
		q14b: "",
		q15: "",
		q16: "0",
		q17: "",
		q17b: "",
		q18: "0",
		q19: "",
		q19b: "",
		q20: "",
		q21: "",
		q22: "",
		q23: "",
		q23b: "",
		q24: "",
		q25: "",
		q26: "",
		q27: "",
		q28: "",
	});

	const surveyContext = {
		survey,
		setSurvey: (newSurvey: ISurvey) => {
			setSurvey(newSurvey);
		},
		updateSurvey: async (newSurvey: Partial<ISurvey>) => {
			if (survey && newSurvey && user) {
				if (Object.keys(newSurvey).length > 0) {
					setSurvey({ ...survey, ...newSurvey });
				}
			}
			return true;
		},
		submitSurvey: async (e?: React.FormEvent<HTMLFormElement>) => {
			e && e.preventDefault();
			if (survey && user) {
				const updatedSurvey = await Server.updateSurveyResponses(user.id, survey);
				surveyContext.updateSurvey(updatedSurvey);
				return !!updatedSurvey?.id;
			}
			return true;
		},
	};
	console.log(window.location.host);

	const qNumbers: number[] = [];
	React.useEffect(() => {
		user?.id &&
			Server.findUserSurvey(user?.id).then((responses: ISurvey) => {
				const tempSurvey: ISurvey = Object.entries(responses).reduce((acc, [key, value]) => {
					if (key in surveyContext.survey) {
						if (typeof survey[key as keyof typeof survey] === "object") {
							return { ...acc, [key]: JSON.parse(value) };
						}
						return { ...acc, [key]: value };
					}
					return acc;
				}, {} as ISurvey);
				setSurvey({ ...tempSurvey });
			});
	}, [user]);

	return (
		<BasePage stepWizardProps={props as unknown as StepWizardChildProps} useDarkTheme={false} userUpdates={{}}>
			<SurveyContext.Provider value={surveyContext}>
				<form onSubmit={(e: React.FormEvent<HTMLFormElement>) => surveyContext.submitSurvey(e)}>
					<Box>
						<Grid container rowSpacing={2}>
							<Grid container justifyContent={"center"} xs={12}>
								<img
									src="/images/maine_mbuf_logo.png"
									alt="ME RUC Logo"
									style={{ width: "150px", marginRight: "300px" }}
								/>
								<Typography variant="h5" sx={{ alignSelf: "center" }}>
									Simulation Survey
								</Typography>
							</Grid>
							<Grid container justifyContent={"center"} xs={12}>
								<Typography variant="h2" marginBottom={2} marginTop={2}>
									The survey should only take 5 minutes to complete. However, if you think you may
									want to leave to finish the survey later, or in case you get disconnected while
									filling out the survey, you may use the Save and Continue Later button located in
									the bottom left corner of this page. You will be provided a unique web link that you
									may use to return to the survey and resume your progress.
								</Typography>
							</Grid>
							<Grid xs={12}>
								<Divider />
							</Grid>
							{Object.entries(surveyQuestions).map(([myKey, question], index) => {
								const qNumber = Number(myKey.replace(/\D/g, ""));
								let hasParent = false;
								if (qNumbers.indexOf(qNumber) === -1) {
									qNumbers.push(qNumber);
								} else {
									hasParent = true;
								}
								if (!hasParent) {
									return (
										<Question
											key={myKey}
											explanation={"explanation" in question ? question.explanation : undefined}
											question={"question" in question ? question.question : undefined}
											myKey={myKey}
											index={index}
											updateSurvey={surveyContext.updateSurvey}
											type={question.type}
										></Question>
									);
								}
							})}

							<Grid xs={12} container justifyContent={"center"} marginTop={"1em"}>
								<Typography variant="h2" marginBottom={2}>
									Thank you for taking the time to complete the simulation and share your thoughts.
									Your feedback will help decision-makers in our state understand how to better fund
									Maine’s roads and bridges.
								</Typography>
							</Grid>
							<Grid xs={12}>
								<Divider />
							</Grid>
							<Grid xs={12} marginBottom={4}>
								<Button
									type="submit"
									sx={{ float: "right" }}
									variant="contained"
									onClick={() => {
										stepWizard.goToNamedStep(SimSteps.Confirmation.name);
										updateUser({
											...user,
											surv_status: true,
											surveyStatus: SurveyStatus.Completed,
											sim_status: SimSteps.Confirmation.name,
											sim_step: SimSteps.Confirmation.stepNumber,
										});
									}}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Box>
				</form>
				<Box
					style={{
						position: "fixed",
						bottom: 0,
						left: 0,
						padding: ".75em",
						width: "100%",
						backgroundColor: "lightGrey",
					}}
					className="save-container"
				>
					<Button
						variant="contained"
						onClick={() => {
							surveyContext.submitSurvey();
							updateUser({
								...user,
								surveyStatus: SurveyStatus.InProgress,
								sim_status: SimSteps.Confirmation.name,
								sim_step: SimSteps.Confirmation.stepNumber,
							});
							setShowSavePopup(true);
						}}
					>
						Save and Continue Later
					</Button>
				</Box>
				<Modal open={showSavePopup} sx={{ display: "flex" }}>
					<div className="save-popup">
						<h2>Survey Progress Saved!</h2>
						<p>
							Come back to this page at any time to continue the survey.
							<br />
							<br />
							For quicker access use this link:
							<br />
							<a href={window.location.origin + "/?id=" + user?.id}>
								{window.location.origin + "/?id=" + user?.id}
							</a>{" "}
						</p>
						<Button
							variant="contained"
							onClick={() => {
								setShowSavePopup(false);
								stepWizard.goToNamedStep(SimSteps.Confirmation.name);
							}}
						>
							Exit
						</Button>
					</div>
				</Modal>
			</SurveyContext.Provider>
		</BasePage>
	);
};

SurveyPage.defaultProps = {
	stepName: SimSteps.Survey.name,
};
