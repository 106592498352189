import { GoogleChartOptions, GoogleChartWrapperChartType } from "react-google-charts";
export const surveyQuestions: {
	[key: string]:
		| {
				question: string;
				short: string;
				chartType?: GoogleChartWrapperChartType;
				type: "textArea";
				text?: string[];
				explanation?: string;
		  }
		| { parent: string; type: string; placeholderText?: string; appearOn?: string }
		| {
				question: string;
				short: string;
				choices?: { [key: string]: number };
				chartType?: GoogleChartWrapperChartType;
				type: "slider";
				units?: string;
				text?: string[];
				buckets: number[];
				maxBucket?: number;
				explanation?: string;
		  }
		| {
				question: string;
				short: string;
				choices?: { [key: string]: number };
				chartType?: GoogleChartWrapperChartType;
				type: "radio";
				text?: string[];
				explanation?: string;
		  }
		| {
				question: string;
				short: string;
				choices?: { [key: string]: number };
				chartType?: GoogleChartWrapperChartType;
				type: "multipleChoice" | "multipleChoiceTextArea";
				units?: string;
				text?: string[];
				buckets?: number[];
				maxBucket?: number;
				explanation?: string;
		  }
		| {
				question: string;
				short: string;
				choices?: { [key: string]: number };
				chartType?: GoogleChartWrapperChartType;
				type: "checkBox";
				text?: string[];
				explanation?: string;
		  };
} = {
	q1: {
		question:
			"How supportive are you of transitioning from funding roads through the state gas tax based on gallons purchased to a mileage-based user fee based on miles driven?",
		short: "How supportive are you of an MBUF Program?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			"Very Supportive": 0,
			"Somewhat Supportive": 0,
			"Somewhat Opposed": 0,
			"Very Opposed": 0,
		},
	},
	q2: {
		question:
			"If a mileage-based user fee was implemented in your state, what percentage of people do you believe would accurately report their miles driven in the past year?",
		short: "What percentage of people do you believe would accurately report their miles?",
		type: "slider",
		units: "percent",
		chartType: "ColumnChart",
		buckets: [0, 100, 10],
		choices: {
			0: 0,
			10: 0,
			20: 0,
			30: 0,
			40: 0,
			50: 0,
			60: 0,
			70: 0,
			80: 0,
			90: 0,
			100: 0,
		},
	},
	q3: {
		question:
			"If a mileage-based user fee was implemented in your state, would you accurately report the number of miles you drove in the past year?",
		short: "Would you accurately report your miles?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			Yes: 0,
			No: 0,
		},
	},
	q4: {
		question:
			"If a mileage-based user fee was implemented in your state and it required drivers to share an odometer photo to verify mileage, what percentage of people do you believe would accurately report their miles driven in the past year? ",
		short: "What percentage of people would accurately report mileage if drivers were required to verify their odometer readings via an image?",
		type: "slider",
		units: "percent",
		chartType: "ColumnChart",
		buckets: [0, 100, 10],
		choices: {
			"0": 0,
			"10": 0,
			"20": 0,
			"30": 0,
			"40": 0,
			"50": 0,
			"60": 0,
			"70": 0,
			"80": 0,
			"90": 0,
			"100": 0,
		},
	},
	q5: {
		explanation:
			"Listed below are some components of the simulation that you just completed. Please tell us whether each resource provided enough information or not enough information for you to understand mileage-based user fee charge reporting and payment.",
		question:
			"Which of the following resources from the simulation did you find to be most	helpful? Select all that apply.",
		short: "Were there enough resources provided?",
		type: "checkBox",
		chartType: "ColumnChart",
		choices: {
			"Intro & General FAQ": 0,
			"Odometer Definition": 0,
			"Mileage Verification": 0,
			"Mileage Exemptions": 0,
			"Mileage Reporting Options": 0,
			"Payment Plans": 0,
			"Invoice FAQ": 0,
			"None of the above": 0,
		},
	},
	q6: {
		question:
			"Would you have any data security concerns if a mileage-based user fee was implemented using a reporting and payment system like the simulation you just completed?",
		short: "Do you have data security concerns related to submitting this information?",
		type: "multipleChoiceTextArea",
		chartType: "PieChart",
		choices: {
			Yes: 0,
			No: 0,
		},
		text: [],
	},
	q6b: {
		parent: "q6",
		type: "textArea",
		placeholderText: "Please explain...",
		appearOn: "Yes",
	},
	q7: {
		question:
			"Rate your general satisfaction with the mileage-based user fee payment and reporting process presented in the simulation.",
		short: "Were you satisfied with the MBUF payment and reporting process?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			"Very Satisfied": 0,
			"Somewhat Satisfied": 0,
			"Somewhat Dissatisfied": 0,
			"Very Dissatisfied": 0,
		},
	},
	q8: {
		question:
			"Approximately how many minutes did it take for you to complete the mileage-based user fee reporting and payment simulation?",
		short: "How long did it take to complete the simulation?",
		type: "slider",
		chartType: "ColumnChart",
		units: "# minutes",
		buckets: [0, 30, 5],
		choices: {
			"0": 0,
			"5": 0,
			"10": 0,
			"15": 0,
			"20": 0,
			"25": 0,
			"30": 0,
		},
	},
	q9: {
		question:
			"Were any steps of the mileage-based user fee reporting and payment simulation difficult to complete?",
		short: "Were any steps difficult to complete?",
		type: "multipleChoiceTextArea",
		chartType: "PieChart",
		choices: {
			Yes: 0,
			No: 0,
		},
		text: [],
	},
	q9b: {
		parent: "q9",
		type: "textArea",
		placeholderText: "Please explain...",
		appearOn: "Yes",
	},
	q10: {
		explanation:
			"In the simulation, every Maine vehicle owner could claim a standard exemption of 200 miles to account for miles driven out of state, on private roads, or off road. Those who want to claim more exempt miles can either provide documentary evidence of those miles or choose a method that automatically exempts miles driven off road and out of state.",
		question:
			"How important would it be for you to be able to claim exemptions for out-of-state and private/off-road miles?",
		short: "How important is it for you to claim exemptions?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			"Very Important": 0,
			"Somewhat Important": 0,
			"Not Very Important": 0,
			"Not Important At All": 0,
		},
	},
	q11: {
		question: "How would you prefer to claim exemptions for out-of-state and private/off-road miles?",
		short: "How would you prefer to claim exemptions for out-of-state and private/off-road miles?",
		type: "radio",
		chartType: "PieChart",
		choices: {
			"I would claim the standard exemption for 200 miles": 0,
			"I would claim more than 200 miles and provide evidence to the state to substantiate my exemption": 0,
			"I would use one of the advanced technology reporting methods (i.e., vehicle telematics, mobile app, or an installed device) to claim more than 200 exempt miles driven": 0,
		},
	},
	q12: {
		question: "Why did you choose your mileage reporting method? Select all that apply.",
		short: "Why did you choose your reporting method?",
		type: "checkBox",
		chartType: "ColumnChart",
		choices: {
			"No device or app needed": 0,
			Cost: 0,
			"Location data not shared": 0,
			"My vehicle doesn't have telematics": 0,
			"Automatic Reporting": 0,
			"Automatic collection of exempt (out-of-state) mileage": 0,
			Other: 0,
		},
		text: [],
	},
	q12b: {
		parent: "q12",
		type: "textArea",
		placeholderText: "Please explain...",
		appearOn: "Other",
	},
	q13: {
		question: "Would you need additional information to select the best mileage reporting option for you?",
		short: "Was additional information needed to select the best mileage reporting option?",
		type: "multipleChoiceTextArea",
		chartType: "PieChart",
		choices: {
			Yes: 0,
			No: 0,
		},
		text: [],
	},
	q13b: {
		parent: "q13",
		type: "textArea",
		placeholderText: "Please explain...",
		appearOn: "Yes",
	},
	q14: {
		question:
			"Do you have privacy or data security concerns with any of the technology-based reporting options (vehicle telematics, plug-in device or mobile app)?",
		short: "Do you have privacy or data security concerns with any of the technology-based reporting options?",
		type: "multipleChoiceTextArea",
		chartType: "PieChart",
		choices: {
			Yes: 0,
			No: 0,
		},
		text: [],
	},
	q14b: {
		parent: "q14",
		type: "textArea",
		placeholderText: "Please explain...",
		appearOn: "Yes",
	},
	q15: {
		question:
			"How satisfied are you with the range of available technology-based mileage reporting options (vehicle telematics, installed device, mobile app)?",
		short: "How satisfied were you with the range of technology options available?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			"Very Satisfied": 0,
			"Somewhat Satisfied": 0,
			"Somewhat Dissatisfied": 0,
			"Very Dissatisfied": 0,
		},
	},
	q16: {
		explanation:
			"Technology-based mileage reporting options (vehicle telematics, installed device, mobile app) have additional features that allow automatic reporting of mileage to the state. They can also automatically record out-of-state mileage for exemptions, or potentially allow drivers to pay a mileage-based user fee monthly, rather than all at once at the end of the year. However, these options are more costly to administer than the manual mileage (odometer photo) reporting option.",
		question: "How much would you be willing to pay per month for these services?",
		short: "How much would you be willing to pay per month for these services?",
		type: "slider",
		buckets: [0, 15, 1],
		units: "currency",
		chartType: "ColumnChart",
		choices: {
			"0": 0,
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
			"6": 0,
			"7": 0,
			"8": 0,
			"9": 0,
			"10": 0,
			"11": 0,
			"12": 0,
			"13": 0,
			"14": 0,
			"15": 0,
		},
	},
	q17: {
		explanation: "During the simulation, two payment options were offered: pay today or four equal payments.",
		question: "How important is it to have payment options as a part of a mileage-based user fee system?",
		short: "How important is it to have payment options as a part of a mileage-based user fee system?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			"Very Important": 0,
			"Somewhat Important": 0,
			"Not Very Important": 0,
			"Not Important At All": 0,
		},
	},
	q17b: {
		question:
			"How important is it for others to have payment options as a part of a mileage-based user fee system?",
		short: "How important is it for others to have payment options as a part of a mileage-based user fee system?",
		type: "multipleChoice",
		chartType: "PieChart",
		choices: {
			"Very Important": 0,
			"Somewhat Important": 0,
			"Not Very Important": 0,
			"Not Important At All": 0,
		},
	},
	q18: {
		explanation: "A flexible payment option could increase the administrative costs of a mileage-based user fee.",
		question:
			"How much would you be willing to add to each of the 4 payments as a service charge to support this payment option?",
		short: "How much would you be willing to add to each of the 4 payments as a service charge to support this payment option?",
		type: "slider",
		buckets: [0, 5, 1],
		units: "currency",
		chartType: "ColumnChart",
		choices: {
			"0": 0,
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
		},
	},
	q19: {
		question:
			"Is there anything that could be added to or removed from the invoice summary to make it easier to understand?",
		short: "Is there anything that could be added to or removed from the invoice summary to make it easier to understand?",
		type: "multipleChoiceTextArea",
		chartType: "PieChart",
		choices: {
			Yes: 0,
			No: 0,
		},
		text: [],
	},
	q19b: {
		parent: "q19",
		type: "textArea",
		placeholderText: "Please explain...",
		appearOn: "Yes",
	},
	q20: {
		question:
			"Are there any other comments you would like to share about a mileage-based user fee or this simulation experience?",
		short: "Are there any other comments you would like to share about a mileage-based user fee or this simulation experience?",
		type: "textArea",
		text: [],
	},
	q21: {
		question:
			"In total, how many vehicles are owned or leased by members of your household? (excluding motorcycles, RVs, and golf carts)",
		short: "In total, how many vehicles are owned or leased by members of your household? ",
		type: "multipleChoice",
		chartType: "ColumnChart",
		units: "# vehicles",
		maxBucket: 5,
		buckets: [1, 5, 1],
		choices: {
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
		},
	},
	q22: {
		question: "What is the fuel type of your primary vehicle?",
		short: "What is the fuel type of your primary vehicle?",
		type: "radio",
		chartType: "PieChart",
		choices: {
			Gasoline: 0,
			Hybrid: 0,
			"Plug-In Electric": 0,
			"Plug-In Hybrid Electric": 0,
			Diesel: 0,
			Other: 0,
		},
	},
	q23: {
		question: "Including yourself, how many people currently live in your household?",
		short: "Including yourself, how many people currently live in your household?",
		type: "multipleChoice",
		chartType: "ColumnChart",
		units: "# people",
		buckets: [1, 6, 1],
		maxBucket: 6,
		choices: {
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
			"6": 0,
		},
	},
	q23b: {
		question: "Of the people in your household, how many are under the age of 18?",
		short: "Of the people in your household, how many are under the age of 18?",
		type: "multipleChoice",
		chartType: "ColumnChart",
		maxBucket: 5,
		units: "# people",
		buckets: [0, 5, 1],
		choices: {
			"0": 0,
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0,
		},
	},
	q24: {
		question: "Which of the following best describes your marital status?",
		short: "Which of the following best describes your marital status?",
		type: "radio",
		chartType: "ColumnChart",
		choices: {
			Married: 0,
			Widowed: 0,
			Divorced: 0,
			Separated: 0,
			"Never Married": 0,
			"Domestic Partnership": 0,
			"Prefer not to answer": 0,
		},
	},
	q25: {
		question: "What is the highest degree or level of schooling that you have completed?",
		short: "What is the highest degree or level of schooling that you have completed?",
		type: "radio",
		chartType: "PieChart",
		choices: {
			"No high school diploma or GED": 0,
			"High school graduate (high school diploma or the equivalent GED)": 0,
			"Some college or Associate degree": 0,
			"Bachelor's degree or higher": 0,
			"Prefer not to answer": 0,
		},
	},
	q26: {
		question: "What is your current employment status?",
		short: "What is your current employment status?",
		type: "radio",
		chartType: "ColumnChart",
		choices: {
			"Working full-time": 0,
			"Working part-time": 0,
			Retired: 0,
			Separated: 0,
			"Not working": 0,
			"Prefer not to answer": 0,
		},
	},
	q27: {
		question: "Which of the following best describes the type or style of housing where you live?",
		short: "Which of the following best describes the type or style of housing where you live?",
		type: "radio",
		chartType: "ColumnChart",
		choices: {
			"One-family house detached from any other house": 0,
			"One-family condo or townhouse attached to other units": 0,
			"Building with 2 or more apartments": 0,
			"Other (mobile home, boat, RV, van, etc.)": 0,
			"Prefer not to answer": 0,
		},
	},
	q28: {
		question: "Which of the following best describes how you pay for housing?",
		short: "Which of the following best describes how you pay for housing?",
		type: "radio",
		chartType: "PieChart",
		choices: {
			"Owned or being bought by you or someone in your household": 0,
			Rented: 0,
			"Occupied without payment of rent": 0,
			"Prefer not to answer": 0,
		},
	},
};

export const chartOptions: { [key: string]: GoogleChartOptions } = {
	ColumnChart: {
		legend: { position: "none" },
		animation: {
			startup: true,
			easing: "out",
			duration: 500,
		},
		vAxis: {
			title: "Number of Respondents",
		},
		backgroundColor: "white",
	},
	PieChart: {
		legend: { position: "top" },
		animation: {
			startup: true,
			easing: "out",
			duration: 500,
		},
		backgroundColor: "white",
	},
};
