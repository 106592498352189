import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useContext, useState } from "react";
import { ISurvey } from "../models";
import { SurveyContext } from "../contexts/SurveyContext";

export {};

export interface CheckboxQuestionProps {
	explanation?: string;
	question: string;
	myKey: any;
	index: number;
	options: string[];
	textarea?: boolean;
	updateSurvey: (newSurvey: Partial<ISurvey>) => Promise<boolean>;
}

export function CheckboxQuestion({ myKey, options, updateSurvey }: CheckboxQuestionProps) {
	const { survey } = useContext(SurveyContext);
	const [value, setValue] = useState<string[] | undefined>((survey[myKey as keyof ISurvey] as string[]) ?? undefined);

	return (
		<>
			<Grid container margin={"0 auto 2em 5em"} justifyContent={"center"} xs={12}>
				{options.map((option) => (
					<Grid xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={(survey[myKey as keyof ISurvey] as string[]).includes(option) ?? false} // check if it is in the list
									key={option}
									onChange={(e) => {
										console.log(survey[myKey as keyof ISurvey]);
										const updatedSurvey = { ...survey };
										const updatedOptions = updatedSurvey[myKey as keyof ISurvey] as string[];
										console.log(option, updatedOptions.includes(option));
										const currentOptions = updatedOptions.includes(option)
											? updatedOptions.filter((o) => o !== option) // if the selected option is already in the list, remove it
											: [...updatedOptions, option]; // if the selected option is not in the list, add it
										updateSurvey({
											[myKey]: [...currentOptions],
										});
									}}
								/>
							}
							label={option}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
}
