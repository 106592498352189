import { Box, Divider, Drawer, Link, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import * as Globals from '../Globals';
import { CloseIcon } from '../icons';
import { IUser, SimSteps } from '../models';
import * as Themes from '../themes';
import { Colors } from '../themes/Common';

export interface SummaryDrawerProps {
    open: boolean,
    useDarkTheme?: boolean,
    desktopWidth: number,
    user: IUser,
    stepWizard: StepWizardChildProps,
    onClose: (() => void)
};

const SummaryRowStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginY: 'auto',
  };

const RoadUseRowStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.938rem',
    borderBottom: '1px solid #CBD1C9',
};

const dollars = new Intl.NumberFormat(`en-US`, {
    currency: `USD`,
    style: 'currency',
});

export const SummaryDrawer: React.FunctionComponent<SummaryDrawerProps> = (props) => {
    const [theme] = React.useState(props.useDarkTheme ? Themes.Dark : Themes.Light);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Drawer
                open={props.open}
                variant={isSmall ? "temporary" : "persistent"}
                anchor="right"
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    width: isSmall ? '100vw' : props.desktopWidth,
                    position: 'relative',
                    marginLeft: "auto",
                    "& .MuiDrawer-paper": {
                        width: isSmall ? '100vw' : props.desktopWidth,
                        position: isSmall ? 'block' : 'absolute',
                        height: 'max(100vh, 100%)',
                    },
                    display: props.open ? 'block' : 'none'
                }}
                onClose={props.onClose}>
                    <Box style={SummaryRowStyles} sx={{
                        paddingY: '1rem',
                        paddingLeft: '1rem',
                        bgcolor: '#F5F5F5',
                    }}>
                        <Typography variant='h2'>
                            Summary
                        </Typography>
                        <Box height='1.313rem' width='100%' sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center'
                            }}>
                            <CloseIcon htmlColor={Colors.blue2} onClick={() => props.onClose()} sx={{
                                cursor: 'pointer',
                                fontSize: '1rem',
                                marginRight: '1.0625rem'
                            }}/>
                        </Box>
                    </Box>
                    <Divider></Divider>
                    <Grid container rowSpacing={1} sx={{
                        marginTop: 0,
                        marginLeft: 0,
                        padding: 1,
                        bgcolor: '#F5F5F5',
                    }}>
                        <Grid xs={12}>
                            <Typography variant='h3' sx={{paddingY: '1rem'}}>
                                Your Vehicle
                            </Typography>
                            <Divider color={Colors.blue3}></Divider>
                        </Grid>
                        <Grid xs={12}>
                            <Box style={SummaryRowStyles}>
                                <Typography>Make</Typography>
                                {props.user?.mpg_override ? 'Custom' : props.user.vehmake || 'Unknown'}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Model</Typography>
                                {props.user?.mpg_override ? 'Custom' : props.user.vehmodel || 'Unknown'}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Year</Typography>
                                {props.user?.mpg_override ? 'Custom' : props.user.vehyear || 'Unknown'}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Email</Typography>
                                {props.user?.email || 'Unknown'}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Average MPG</Typography>
                                {(props.user?.mpg || '--') + ' mi.'}
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h3' sx={{paddingY: '1rem'}}>
                                Road Use Plan
                            </Typography>
                            <Divider color={Colors.blue3}></Divider>
                            <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.MileageReporting.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.MileageReporting.name)}>Current Odometer</Link>
                                : <Typography variant="h4">Current Odometer</Typography>}
                                {props.user?.odo && (props.user.odo + ' mi.')}
                            </Box>
                            <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.MileageVerification.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.MileageVerification.name)}>Odometer Photo</Link>
                                : <Typography variant="h4">Odometer Photo</Typography>}
                                {
                                props.user?.odo_pic === 'Decline Submission' ? 'Declined'
                                : !!props.user?.odo_pic ? 'Uploaded' : ''
                                }
                            </Box>
                            <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.Exemption.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.Exemption.name)}>Exemptions</Link>
                                : <Typography variant="h4">Exemptions</Typography>}
                                {props.user && props.user.effectiveExemptMiles && (props.user.effectiveExemptMiles() + ' mi.')}
                            </Box>
                            <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.ReportingOption.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.ReportingOption.name)}>Reporting</Link>
                                : <Typography variant="h4">Reporting</Typography>}
                                {props.user && props.user.mi_report}
                            </Box>
                            {/* <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.Discounts.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.Discounts.name)}>Income-Based Discounts</Link>
                                : <Typography variant="h4">Income-Based Discounts</Typography>}
                                {(props.user && props.user.hasDiscount && props.user.hasDiscount()) ? 'Yes' : 'None'}
                            </Box> */}
                            <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.PaymentPlan.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.PaymentPlan.name)}>Payment Plan</Link>
                                : <Typography variant="h4">Payment Plan</Typography>}
                                {props.user && props.user.pay_opt}
                            </Box>
                            <Box style={RoadUseRowStyles}>
                                {(props.user?.sim_step || 0) > SimSteps.PaymentMethod.stepNumber
                                ? <Link sx={{cursor: 'pointer'}} onClick={() => props.stepWizard.goToNamedStep(SimSteps.PaymentMethod.name)}>Payment Type</Link>
                                : <Typography variant="h4">Payment Type</Typography>}
                                {props.user && props.user.pay_method}
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h3' sx={{paddingY: '1rem'}}>
                                Mileage-based User Charge
                            </Typography>
                            <Divider color={Colors.blue3}></Divider>
                        </Grid>
                        <Grid xs={12}>
                            <Box style={SummaryRowStyles}>
                                <Typography>Chargeable Miles</Typography>
                                {((props.user && props.user.chargeableMiles ? props.user.chargeableMiles() : 0 ) || '--') + ' mi.'}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Charge-Per-Mile</Typography>
                                ${parseFloat(Globals.dollarsPerMile.toFixed(3))}
                            </Box>
                            {/* <Box style={SummaryRowStyles}>
                                <Typography>Income-Based Discount</Typography>
                                {dollars.format(props.user && props.user.discountAmount ? props.user.discountAmount() : 0)}
                            </Box> */}
                            <Box style={SummaryRowStyles}>
                                <Typography><b>Subtotal</b></Typography>
                                <b>{dollars.format(props.user && props.user.subTotal ? props.user.subTotal() : 0)}</b>
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Est. Gas Taxes Paid</Typography>
                                {dollars.format(props.user && props.user.estimatedGasFee ? props.user.estimatedGasFee() : 0)}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography>Transaction Fee</Typography>
                                {dollars.format(props.user && props.user.transactionFee ? props.user.transactionFee() : 0)}
                            </Box>
                            <Box style={SummaryRowStyles}>
                                <Typography><b>Total</b></Typography>
                                <b>{dollars.format(props.user && props.user.totalDue ? props.user.totalDue() : 0)}</b>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box height='100%' bgcolor='#F5F5F5'></Box>
                </Drawer>
            </ThemeProvider>
    );
}

SummaryDrawer.defaultProps = {
    useDarkTheme: false
}

export default SummaryDrawer;
