import { Grid, Typography, Button } from "@mui/material";
import { useContext } from "react";
import { ISurvey } from "../models";
import { SurveyContext } from "../contexts/SurveyContext";

export interface VisualRadioQuestionProps {
	myKey: any;
	options: string[];
	updateSurvey: (newSurvey: Partial<ISurvey>) => Promise<boolean>;
}

export function VisualRadioQuestion({ myKey, options, updateSurvey }: VisualRadioQuestionProps) {
	const { survey } = useContext(SurveyContext);
	return (
		<>
			<Grid className="visualButtons" container marginBottom={"2em"} justifyContent={"center"} xs={12}>
				{options.map((option) => (
					<Button
						variant="contained"
						style={{
							backgroundColor: survey[myKey as keyof ISurvey] === option ? "darkblue" : "",
						}}
						onClick={() => {
							updateSurvey({ [myKey]: option });
						}}
					>
						{option}
					</Button>
				))}
			</Grid>
		</>
	);
}
