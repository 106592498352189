import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Box, Button, Divider, Drawer, ThemeProvider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { FAQ } from "../components";
import { UserContext } from "../contexts";
import { PaymentMethods, PaymentPlans, SimSteps, SurveyStatus, SurveyUrl } from "../models";
import * as Themes from "../themes";
import { Colors } from "../themes/Common";
import BasePage, { StepWizardPageProps } from "./BasePage";

let todaysDate = new Date();
let PaymentDate = todaysDate.toLocaleDateString("en-US", {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
});

const DummyPaymentMethods = {
	CreditCard: "VISA *1234",
	BankAccount: "CHECKING *1234",
	PaymentApp: "PayPal",
	CashCheck: "Check #1234",
};

const dollars = new Intl.NumberFormat(`en-US`, {
	currency: `USD`,
	style: "currency",
});

const ConfirmationHeaderRowStyles = {
	display: "flex",
	alignContent: "center",
	height: "2.063rem",
};

const ConfirmationRowStyles = {
	display: "flex",
	justifyContent: "space-between",
	marginY: "auto",
};

export const ConfirmationPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
	const { user, updateUser } = React.useContext(UserContext);
	const [showBanner, setShowBanner] = React.useState(false);
	const appInsights = useAppInsightsContext();

	const stepWizard = props as unknown as StepWizardChildProps;
	const isQuarterly = user?.pay_opt === PaymentPlans.FourPayments.value;
	const paymentPlanAmount = (user && user?.totalDue && user?.totalDue() / 4) || 0;

	React.useEffect(() => {
		if (stepWizard.isActive && !showBanner && !(user?.surveyStatus === SurveyStatus.Completed)) {
			const timeout = setTimeout(() => {
				setShowBanner(true);
			}, 2000);
			return () => clearTimeout(timeout);
		} else if (user?.surv_status === true) {
			setShowBanner(false);
		}
	}, [showBanner, stepWizard.isActive, user?.surveyStatus]);

	const handlePrintClicked = () => {
		appInsights.trackEvent({ name: "ClickedPrint" });
		window.print();
		updateUser({ print: true });
	};

	// const handleSurveyClicked = () => {
	// 	appInsights.trackEvent({ name: "ClickedSurvey" });
	// 	if (user) {
	// 		if (user.redirect_url) {
	// 			let append = "";
	// 			if (user.recruit === "IPSOS") {
	// 				append = "&ext_st=1&intlen=10&termpoint8=";
	// 			}
	// 			window.open(user.redirect_url + append);
	// 		} else if (user.email) {
	// 			window.open(SurveyUrl.Ei + "?id=" + user.email);
	// 		} else {
	// 			// Should only be possible with users with no license, and did not come from an IPSOS link
	// 			// I.e. only test users
	// 			props.previousPage();
	// 		}
	// 	}
	// };

	return (
		<BasePage stepWizardProps={stepWizard} userUpdates={{}} showLogo={true}>
			<Box sx={{ position: "relative" }} displayPrint="none">
				<Button
					variant="contained"
					sx={{
						right: 0,
						top: "-3rem",
						position: "absolute",
					}}
					onClick={handlePrintClicked}
				>
					Print
				</Button>
			</Box>
			<Box
				sx={{
					paddingTop: "6rem",
					paddingBottom: "2.5rem",
				}}
			>
				<Typography variant="h2" paddingTop={3}>Payment confirmed!</Typography>
			</Box>
			<Grid container sx={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
				<Grid
					xs={12}
					md={4}
					sx={{
						paddingBottom: "2rem",
						"@media print and (min-width: 700px)": {
							width: "calc(100% * 4 / var(--Grid-columns));",
						},
					}}
				>
					<Grid xs={12} sx={{ paddingBottom: "1rem" }}>
						<Box style={ConfirmationHeaderRowStyles}>
							<Typography variant="h3">Payment Details</Typography>
						</Box>
						<Divider color={Colors.blue3}></Divider>
					</Grid>
					<Grid xs={12}>
						<Box style={ConfirmationRowStyles}>
							<Typography>Confirmation</Typography>
							<b style={{ fontWeight: 600 }}>11019260624</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Payment Date</Typography>
							<b style={{ fontWeight: 600 }}>{PaymentDate}</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Payment Type</Typography>
							<b style={{ fontWeight: 600 }}>{user && user?.pay_method && user?.pay_method}</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							{user?.pay_method === PaymentMethods.CreditCard.value && (
								<>
									<Typography>Card Details</Typography>
									<b style={{ fontWeight: 600 }}>{DummyPaymentMethods.CreditCard}</b>
								</>
							)}
							{user?.pay_method === PaymentMethods.BankAccount.value && (
								<>
									<Typography>Bank Details</Typography>
									<b style={{ fontWeight: 600 }}>{DummyPaymentMethods.BankAccount}</b>
								</>
							)}
							{user?.pay_method === PaymentMethods.PaymentApp.value && (
								<>
									<Typography>App Details</Typography>
									<b style={{ fontWeight: 600 }}>{DummyPaymentMethods.PaymentApp}</b>
								</>
							)}
							{user?.pay_method === PaymentMethods.CashCheck.value && (
								<>
									<Typography>Check Details</Typography>
									<b style={{ fontWeight: 600 }}>{DummyPaymentMethods.CashCheck}</b>
								</>
							)}
						</Box>
					</Grid>
					<Grid xs={12} sx={{ paddingTop: "2rem", paddingBottom: "1rem" }}>
						<Box style={ConfirmationHeaderRowStyles}>
							<Typography variant="h3">Your Vehicle</Typography>
						</Box>
						<Divider color={Colors.blue3}></Divider>
					</Grid>
					<Grid xs={12}>
						<Box style={ConfirmationRowStyles}>
							<Typography>Make</Typography>
							<b style={{ fontWeight: 600 }}>
								{user?.mpg_override ? "Self-entered" : user?.vehmake || "Unknown"}
							</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Model</Typography>
							<b style={{ fontWeight: 600 }}>
								{user?.mpg_override ? "Self-entered" : user?.vehmodel || "Unknown"}
							</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Year</Typography>
							<b style={{ fontWeight: 600 }}>
								{user?.mpg_override ? "Self-entered" : user?.vehyear || "Unknown"}
							</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Email</Typography>
							<b style={{ fontWeight: 600 }}>{user?.email || "Unknown"}</b>
						</Box>
					</Grid>
				</Grid>
				<Grid
					xs={12}
					md={8}
					sx={{
						paddingLeft: { xs: 0, md: "1rem" },
						"@media print and (min-width: 700px)": {
							width: "calc(100% * 8 / var(--Grid-columns));",
							paddingLeft: "1rem",
							paddingTop: 0,
						},
					}}
				>
					<Grid xs={12} sx={{ paddingBottom: "1rem" }}>
						<Typography variant="h3" style={ConfirmationHeaderRowStyles}>
							Mileage-based User Charge
						</Typography>
						<Divider color={Colors.blue3}></Divider>
					</Grid>
					<Grid xs={12}>
						<Box style={ConfirmationRowStyles}>
							<Typography>Mileage-based User Fee</Typography>
							<b style={{ fontWeight: 600 }}>
								{dollars.format(user && user?.estimatedRucFee ? user?.estimatedRucFee() : 0)}
							</b>
						</Box>
						{/* <Box style={ConfirmationRowStyles}>
                            <Typography>Income-based Discounts</Typography>
                            <b style={{fontWeight: 600}}>{dollars.format(user && user?.discountAmount ? user?.discountAmount() : 0)}</b>
                        </Box> */}
						<Box style={ConfirmationRowStyles}>
							<Typography>Estimated Gas Taxes Paid</Typography>
							<b style={{ fontWeight: 600 }}>
								{dollars.format(user && user?.estimatedGasFee ? user?.estimatedGasFee() : 0)}
							</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Transaction Fee (3%)</Typography>
							<b style={{ fontWeight: 600 }}>
								{dollars.format(user && user.transactionFee ? user.transactionFee() : 0)}
							</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>Total</Typography>
							<b style={{ fontWeight: 600 }}>
								{dollars.format(user && user?.totalDue ? user?.totalDue() : 0)}
							</b>
						</Box>
						<Box style={ConfirmationRowStyles}>
							<Typography>
								<b>Today's Charge</b>
							</Typography>
							<b>
								{isQuarterly
									? dollars.format(paymentPlanAmount)
									: user && user?.totalDue && dollars.format(user?.totalDue())}
							</b>
						</Box>
					</Grid>
					{user && user?.totalDue().toFixed(2) !== "0.00" && isQuarterly && (
						<Grid xs={12} sx={{ paddingY: "0.813rem" }}>
							<Box style={ConfirmationHeaderRowStyles}>
								<Typography variant="h3">Remaining Payments</Typography>
							</Box>
							<Divider color={Colors.blue3}></Divider>
							<Box style={ConfirmationRowStyles} paddingTop="1rem">
								<Typography>Payment 2</Typography>
								<b style={{ fontWeight: 600 }}>{dollars.format(paymentPlanAmount)}</b>
							</Box>
							<Box style={ConfirmationRowStyles}>
								<Typography>Payment 3</Typography>
								<b style={{ fontWeight: 600 }}>{dollars.format(paymentPlanAmount)}</b>
							</Box>
							<Box style={ConfirmationRowStyles}>
								<Typography>Payment 4</Typography>
								<b style={{ fontWeight: 600 }}>{dollars.format(paymentPlanAmount)}</b>
							</Box>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid container width="100%" sx={{ pageBreakAfter: "always" }}>
				<Box minHeight="2rem"></Box>
			</Grid>
			<Grid container bgcolor={Colors.gray1} sx={{ position: "absolute", left: 0, width: "100%" }}>
				<Grid xs={12}>
					<Box
						sx={{
							paddingTop: 4,
							paddingBottom: { xs: "18rem", md: "13rem" },
							paddingX: "0.75rem",
							maxWidth: "35.42rem",
							marginX: "auto",
						}}
					>
						<FAQ autoExpand={true} />
					</Box>
				</Grid>
			</Grid>
			<Drawer open={showBanner} variant="persistent" anchor="bottom" sx={{ height: "10rem" }}>
				<ThemeProvider theme={Themes.Dark}>
					<Box bgcolor="background.default" paddingX={Themes.Dark.minContentMargin} displayPrint="none">
						<Box
							sx={{
								marginTop: "2.5rem",
								marginBottom: { xs: "0.75rem", md: "3.25rem" },
								maxWidth: Themes.Dark.maxContentWidth,
								marginX: "auto",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Grid container rowSpacing={1.5} width="100%">
								<Grid xs={12} md={1}>
									<img
										src="/images/pilot.svg"
										alt="Pilot"
										style={{ margin: "0px auto 0px", display: "block" }}
									/>
								</Grid>
								<Grid xs={12} md={7}>
									<Typography
										sx={{
											marginLeft: { xs: 0, md: "1.5rem" },
											maxWidth: "32.45rem",
											height: "100%",
											display: "flex",
											paddingY: "auto",
											alignItems: "center",
										}}
									>
										{user?.recruit === "IPSOS"
											? "Thank you for completing the simulation. Please click ‘Next’ to be re-directed back to KnowledgePanel for some questions about your experience."
											: "Thank you for completing the simulation. To receive your thank-you reward, please click the button to tell us now about your experience by answering a few questions."}
									</Typography>
								</Grid>
								<Grid xs={12} md={4}>
									<Box
										sx={{
											height: "100%",
											display: "flex",
											paddingY: "auto",
											alignItems: "center",
											justifyContent: "right",
										}}
									>
										<Button
											variant="contained"
											onClick={() => {
												user?.surveyStatus === SurveyStatus.InProgress
													? stepWizard.goToNamedStep(SimSteps.Survey.name)
													: props.nextPage();
											}}
										>
											{user?.recruit === "IPSOS"
												? "Next"
												: user?.surveyStatus === SurveyStatus.InProgress
												? "Continue survey"
												: "Tell us now."}
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</ThemeProvider>
			</Drawer>
		</BasePage>
	);
};

ConfirmationPage.defaultProps = {
	stepName: SimSteps.Confirmation.name,
};
