import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import Markup from 'react-html-markup';
import { ExpandArrow } from '../icons';

interface QandA {
    question: string,
    answer: string[]
}

const qAndAs: QandA[] = [
    {
        question: "What is a mileage-based user fee?",
        answer: [
            "A mileage-based user fee is a per-mile charge drivers would pay based on how much they use Maine’s road system rather than by the gallons of gas they buy.",
        ]
    },
    {
        question: "What is the per-mile rate of the mileage-based user fee?",
        answer: [
            "The rate is 1.37 cents per mile. This is what the average Maine vehicle currently pays in state gas taxes at 30 cents per gallon. However, most vehicles actually pay more or less than this number. Under a mileage-based user fee, all vehicles would pay the same.",
        ]
    },
    {
        question: "Why is Maine state studying a mileage-based user fee system?",
        answer: [
            "<u>To ensure sustainable, long-term funding</u>: As vehicles become more fuel efficient, gas consumption goes down. With a decline in gas consumption comes reduced gas tax revenues needed for our roads, bridges, and ferry system. A mileage-based user fee could provide a more stable source of transportation funding than the gas tax since drivers would pay by the mile instead of by the gallon. A per-mile charge more directly ties road usage to costs.",
            "<u>To ensure everyone pays their fair share</u>: Considering the range of miles per gallon (mpg) of today’s vehicles on the road, the gas tax has become inequitable. For the same miles driven, drivers pay widely different amounts for their roadway use depending on their vehicle’s miles per gallon. This inequity is expected to grow each year as vehicle miles per gallon continues to increase.",
        ]
    },
    {
        question: "Will this be an additional tax?",
        answer: [
            "This is only a study. In this simulation, the mileage-based user fee is being considered as a replacement to the gas tax, not in addition to the gas tax. On your summary of charges you will notice that we have estimated the amount paid in gas tax based on your vehicle’s miles per gallon and reported mileage. This amount has been deducted from your final mileage-based user fee amount to avoid double payment.",
        ]
    },
    {
        question: "What is this simulation exploring?",
        answer: [
            "This mileage-based user fee simulation is a small-scale, short-term study that gives Maine drivers a chance to experience different approaches to road usage charging. This research simulation also gives drivers an idea of how a real system could work and provides an opportunity to share their feedback.",
        ]
    },
    {
        question: "What happens if I drive out of state or in private roads?",
        answer: [
            "One option is to allow a standard exemption for all drivers. Another option is to allow drivers to claim exemptions for miles driven out of state or off public roads. You can choose this option as part of the simulation. A third option, tested in previous research in Maine, is to allow drivers to choose a location-enabled mileage reporting technology that can automatically deduct miles driven out of state or off public roads from the amount they owe."
        ]
    },
    {
        question: "Can miles be reported without using GPS data?",
        answer: [
            "Yes. Participants can select “Self-Reporting”. This option requires participants to report their vehicle’s odometer reading via a web-based account system. The odometer reading approach does not require any technology or GPS to utilize.",
        ]
    },
    {
        question: "Do participants have to pay with their own (real) money?",
        answer: [
            "No. Participants are not required to pay real money.",
        ]
    },
    {
        question: "How would out-of-state drivers be handled in a mileage-based user fee system in Maine state?",
        answer: [
            "If a mileage-based user fee system is put in place in the future, the gas tax will likely need to stay in place while the state slowly transitions away from it. During this time, drivers would only pay the mileage-based user fee or the gas tax, but not both. As long as the gas tax stays in place, out-of-state drivers would continue to pay the gas tax at the pump, just like they do today.",
        ]
    },
    {
        question: "Have other states adopted a mileage-based user fee?",
        answer: [
            "Utah, Oregon, and Virginia are the three states with currently operating programs. Additionally, last year, Hawaii passed legislation to begin a mileage-based user fee. Implementation is underway and the Hawaii program will begin in July 2025. About a dozen states are exploring and developing their own programs.",
        ]
    },
]

export const FAQ: React.FunctionComponent<{autoExpand?: boolean, sx?: SxProps<Theme>}> = (props) => {
    const appInsights = useAppInsightsContext();

    const handleAccordianChanged = (question: string, expanded: boolean) => {
        if (expanded) {
            appInsights.trackEvent({name: "ShowFAQAnswer"}, { question });
        }
    }

    return (
        <Box sx={props.sx}>
            <Box>
                <Grid container rowSpacing={2}>
                    <Grid xs={12}>
                        <Typography variant="h3">
                            Frequently Asked Questions
                        </Typography>
                        <Divider sx={{marginTop: 1}}/>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container rowSpacing={0}>
                    {qAndAs.map((item) =>
                    <Grid key={item.question} xs={12}>
                        <Divider />
                        <Accordion sx={{pageBreakInside: 'avoid'}} onChange={(event, expanded) => handleAccordianChanged(item.question, expanded)}>
                            <AccordionSummary  expandIcon={<ExpandArrow fontSize='small'/>}>
                                <Typography variant="h2">
                                    {item.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{display: 'flex', flexDirection: 'column', gridGap: '0.5rem'}}>
                                    {item.answer.map((answer) =>
                                    <Typography>
                                        <Markup htmlString={answer} htmlTag='span'/>
                                    </Typography>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}

FAQ.defaultProps = {
    autoExpand: false
};
