import { createContext } from "react";
import { ISurvey } from "../models";

export const SurveyContext = createContext<{
	survey: ISurvey;
	setSurvey: (survey: ISurvey) => void;
	updateSurvey: (survey: Partial<ISurvey>) => Promise<boolean>;
	submitSurvey: (e?: React.FormEvent<HTMLFormElement>) => Promise<boolean>;
}>({
	survey: {} as ISurvey,
	setSurvey: (survey: ISurvey) => {},
	updateSurvey: async (survey: Partial<ISurvey>) => true,
	submitSurvey: async (e?: React.FormEvent<HTMLFormElement>) => true,
});
