import { Grid, Typography, Slider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ISurvey } from "../models";
import { SurveyContext } from "../contexts/SurveyContext";

export {};

export interface SliderQuestionProps {
	myKey: any;
	units: string;
	range: [number, number];
	updateSurvey: (newSurvey: Partial<ISurvey>) => Promise<boolean>;
}

export function SliderQuestion({ myKey, units, range, updateSurvey }: SliderQuestionProps) {
	const { survey } = useContext(SurveyContext);
	const [minimum, maximum] = range;

	const [value, setValue] = useState<number>(0);
	const [label, setLabel] = useState<string>("");
	const updateLabel = (value: number) => {
		switch (units) {
			case "percent":
				setLabel(`${value}%`);
				break;
			case "currency":
				setLabel(`$${value}`);
				break;
			case "# minutes":
				setLabel(`${value} minutes`);
				break;
			default:
				const unit = units.replace("#", "");
				setLabel(`${value} ${unit}`);
		}
	};
	useEffect(() => {
		updateLabel(value);
		survey[myKey as keyof ISurvey] && setValue(Number(survey[myKey as keyof ISurvey]));
	}, [value, survey, myKey]);
	return (
		<>
			<Grid container justifyContent={"center"} xs={12}>
				<Typography id="slider" gutterBottom variant="h3">
					{label}
				</Typography>
				<Grid xs={12} container justifyContent={"center"}>
					<Slider
						min={minimum}
						max={maximum}
						step={1}
						value={value}
						onChange={(e: Event, newValue: number | number[]) => {
							if (typeof newValue === "number") {
								setValue(newValue);
								updateLabel(newValue);
								updateSurvey({ [myKey]: newValue });
							}
						}}
						valueLabelFormat={(value) => `${value}%`}
						style={{ width: "400px" }}
					></Slider>
				</Grid>
			</Grid>
		</>
	);
}
