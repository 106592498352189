import { Grid, Typography, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ISurvey } from "../models";
import { SurveyContext } from "../contexts/SurveyContext";

export {};

export interface RadioQuestionProps {
	explanation?: string;
	question: string;
	myKey: any;
	index: number;
	options: string[];
	textarea?: boolean;
	updateSurvey: (newSurvey: Partial<ISurvey>) => Promise<boolean>;
}

export function RadioQuestion({ myKey, options, updateSurvey }: RadioQuestionProps) {
	const { survey } = useContext(SurveyContext);
	const [value, setValue] = useState<string | undefined>(survey[myKey as keyof ISurvey] as string);
	useEffect(() => {
		survey[myKey as keyof ISurvey] && setValue(survey[myKey as keyof ISurvey] as string);
	}, [survey, myKey]);
	return (
		<>
			<RadioGroup
				value={value}
				onChange={(e: ChangeEvent<HTMLInputElement>, newValue: string) => {
					setValue(newValue);
					updateSurvey({ [myKey]: newValue });
				}}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "left",
					margin: "0 auto 2em 5em",
				}}
			>
				{options.map((option) => (
					<FormControlLabel
						control={<Radio />}
						value={option}
						key={option}
						label={option}
						onClick={(e) => setValue((e.target as HTMLInputElement).value)}
					/>
				))}
			</RadioGroup>
		</>
	);
}
